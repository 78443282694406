@import '../style/main.scss';


.display-line-break {
  white-space: pre-line;
}

.login-form {
  .form-title {
    color: #323942;
    text-align: center;
    font-size: 36px;
    // font-family: Nunito Sans;
    font-family: 'proxima_nova_ltsemibold';
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }

  .form-sub-title {
    color: #323942;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.14px;
  }

  .form-label-view {
    color: #323942;
    font-size: 14px;
    font-family: 'proxima_nova_ltsemibold';
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.14px;
  }

  .form-input-view {
    border-radius: 4px;
    border: 1px solid #ebebeb;
    background: #fff;
  }

  .form-footer-text {
    color: #323942;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.14px;

    a {
      color: #019ce1 !important;
    }
  }
}
