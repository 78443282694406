@import '../../style/main.scss';

.f-search {
  color: #323942;
  font-size: 14px;
  font-family: 'proxima_nova_ltsemibold';
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.14px;
}

.accordion {
  .accordion-item {
    .accordion-button {
      padding: 10px 16px;
      &:not(.collapsed) {
        border-radius: 4px 4px 0px 0px;
        border: 1px solid #ebebeb;
        background: #6f8395;

        // text
        font-family: 'proxima_nova_ltsemibold';
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.14px;
      }

      &.collapsed {
        color: #323942;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.14px;
      }
    }

    .accordion-body {
      color: #323942;
      font-size: 12px;
      line-height: 140%;
      letter-spacing: 0.12px;

      .gray-dark {
        color: #323942;
        font-size: 14px;
        font-family: 'proxima_nova_ltsemibold';
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.14px;
        text-transform: uppercase;

        background-color: transparent;
        border: 0;
        padding-left: 0;
        padding-right: 0;

        &.level-2{
          font-size: 13px!important;
          color: #495057;
        }
        &.level-3{
          font-size: 12px!important;
          color: #6c757d;
        }
      }

      strong {
        font-family: 'proxima_nova_ltsemibold';
        font-weight: 600;
      }

      .card {
        border: 0;

        .card-header {
          color: #323942;
          font-size: 14px;
          font-family: 'proxima_nova_ltsemibold';
          font-weight: 700;
          line-height: 140%;
          letter-spacing: 0.14px;
          text-transform: uppercase;

          background-color: transparent;
          border: 0;
          padding-left: 0;
          padding-right: 0;
        }

        .card-body {
          padding: 0;
          .card-text {
          }
        }
      }
    }
  }
}
.pre-page-text {
  color: #323942;

  /* Form placeholder filled */
  font-size: 14px;

}

.pagination {
  .page-item {
    &.active {
      .page-link{
        background-color: $blue;
        color: #fff;
        border-color: $blue;
      }
    }

    .page-link {
      color: $blue;
    }
  }
}


.status-color-point {
  border-left: 8px;
}
// .status-color-point {
//   width: 20px;
//   height:20px;
//   border-radius: 50%;
// }

.border-left{
  border-top-left-radius: 10%;
  border-bottom-left-radius: 10%;
  border-left-width: 8px;

  &.bg-clear {
    // background-color: $green;
    border-color: transparent;
  }
  &.bg-green {
    // background-color: $green;
    border-color: $green;
  }
  &.bg-yellow {
    // background-color: #ffc107;
    border-color: #ffc107;
  }
  &.bg-orange {
    // background-color: #fd7e14;
    border-color: #fd7e14;
  }
  &.bg-red {
    // background-color: #dc3545;
    border-color: #dc3545;
  }
  &.bg-blue {
    // background-color: $blue;
    border-color: $blue;
  }
}
