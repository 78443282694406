@import '../style/main.scss';

.b-sidebar {
  background: #323942;

  /* sidebar shadow */
  box-shadow: 6px 0px 18px 0px rgba(0, 0, 0, 0.06);

  .sb-title {
    color: #fff;
    font-size: 24px;
    font-family: 'proxima_nova_ltsemibold';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  #menu {
    a {
      color: #acc0cf !important;
      font-size: 14px;
      font-family: 'proxima_nova_ltsemibold';
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.14px;
      text-transform: uppercase;
    }

    .active {
      background-color: #6f8395;
      border-left: 2px solid $blue;
      a {
        color: #fff !important;
      }
    }
  }

  #dropdownUser1 {
    color: #acc0cf !important;
    font-size: 14px;
    font-family: 'proxima_nova_ltsemibold';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.14px;
    text-transform: uppercase;
  }

  .fixed-bottom-user {
    position: fixed;
    bottom: 0px;
    // width: auto;
    z-index: 9999;
    left: 0px;
  }

  .d-sidebar-show {
    display: inline !important;
  }
  .d-sidebar-hidden {
    display: none !important;
  }
}

.alert-fixed {
  position: fixed;
  top: 10px;
  /* left: 30%; */
  width: auto;
  z-index: 9999;
  right: 10px;
  border-radius: 15px;
}

.display-line-break {
  white-space: pre-line;
}

.page-title {
  background: #6f8395;

  color: #fff;
  font-size: 28px;
}

input,
select {
  // border-radius: 0% !important;
  border: 0;
}
.btn {
  // border-radius: 0% !important;
  border-radius: 4px !important;
  &.btn-primary {
    background-color: $blue !important;
    border-color: $blue !important;
  }

  &.btn-success {
    background-color: $green !important;
    border-color: $green !important;
  }
}

table {
  tr {
    th {
      color: #323942 !important;
      font-size: 16px;
      font-family: 'proxima_nova_ltsemibold';
      font-weight: 700;
      text-transform: capitalize;
    }

    td {
      color: #323942 !important;
      font-size: 16px;
      letter-spacing: 0.4px;
    }
  }
  tbody > tr:nth-child(4n + 1) {
    td {
      background-color: #f3f5f7 !important;
    }
  }
}



table {
  &.bg-border-left {
    border-collapse: separate;
    border-spacing: 0;
  }
}
.table-responsive {
  overflow: visible;
}
.table-row {
  z-index: 1;

  input {
    min-width: 80px;
  }
}

.basic-select-single {
  min-width: 50px;
  .select__menu {
    z-index: 999;
    position: absolute;
    min-width: 125px;
  }
}
