@font-face {
    font-family: 'proxima_nova_altblack';
    src: url('../assets/fonts/Mark_Simonson_-_Proxima_Nova_Alt_Black-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'proxima_nova_altbold';
    src: url('../assets/fonts/Mark_Simonson_-_Proxima_Nova_Alt_Bold-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/Mark_Simonson_-_Proxima_Nova_Alt_Regular-webfont.ttf')
    format('truetype');
}


@font-face {
    font-family: 'proxima_nova_ltsemibold';
    src: url('../assets/fonts/Mark_Simonson_-_Proxima_Nova_Semibold-webfont.ttf') format('truetype');
}

$blue: #019ce1;
$green: #7cb760;

body {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

root,
[data-bs-theme='light'] {
  --bs-blue: #019ce1;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #019ce1;
  --bs-btn-border-color: #019ce1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #019ce1;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #019ce1;
  --bs-btn-disabled-border-color: #019ce1;
}
